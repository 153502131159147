import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
export default function BlogsComponent({ blogs }) {
  return (
    <>
      <head>
        <title>{blogs.title}</title>
        <meta charSet="UTF-8" />
        {blogs.meta.map((meta) => {
          return (
            <>
              <meta name={meta.name} content={meta.content} />
            </>
          );
        })}
      </head>
      <Box padding="0 10vw">
        <Typography
          padding={{ xs: "32px 0", md: "32px 5vw" }}
          variant="h3"
          fontWeight="600"
          fontSize={{ xs: "36px", lg: "45px" }}
          textAlign={{ xs: "left", md: "center" }}
        >
          {blogs.heading}
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="center">
          {/* <Typography
            color="black"
            padding="2vh 0"
            fontWeight="400"
            variant="subtitle"
          >
            {blogs.cityName} / {blogs.location}
          </Typography> */}
          <picture
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <source
              media="(min-width:992px)"
              srcSet={blogs.bannerImg.url_large}
            />
            <source
              media="(min-width:768px)"
              srcSet={blogs.bannerImg.url_medium}
            />
            <img
              src={blogs.bannerImg.url_small}
              alt={blogs.bannerImg.altText}
              title={blogs.bannerImg.title}
            />
          </picture>
          <Typography
            color="#121416"
            fontSize={{ xs: "10px", md: "14px", lg: "16px" }}
            lineHeight="27px"
            fontFamily="Inter"
          >
            {blogs.bannerImg.source}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" padding="5vh 0">
          <Box display="flex" gap={2} alignItems="center">
            <Box
              borderRadius="50%"
              overflow="hidden"
              width="70px"
              height="70px"
            >
              {blogs.authorImgSmall ? (
                <img src={blogs.authorImgSmall} width="70px" height="70px" />
              ) : (
                <img
                  src="/assets/images/userimg.png"
                  width="70px"
                  height="70px"
                />
              )}
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography fontSize="18px">{blogs.author}</Typography>
              <Typography variant="subtitle" fontSize="14px">
                {blogs.date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Box
            padding={{ xs: "32px 0px", md: "32px 5vw" }}
            maxWidth={{ xs: "600px", md: "600px", lg: "600px", xl: "700px" }}
          >
            {blogs.content.map((item, index) => {
              switch (item.type) {
                case "intro-para":
                  return item.text.map((item) => {
                    return (
                      <Typography
                        className="text-readability"
                        padding="0 0 2em"
                        color="#121416"
                        fontSize="16px"
                      >
                        {item.text}
                      </Typography>
                    );
                  });
                case "tableOfContents":
                  return (
                    <>
                      <Typography
                        className="text-readability"
                        padding="2vh 0"
                        fontFamily="Inter"
                        fontWeight="600"
                        color="#121416"
                        fontSize="28px"
                      >
                        Table of Contents
                      </Typography>
                      <ul style={{ paddingBottom: "23px" }}>
                        {item.text.map((item) => {
                          return (
                            <li>
                              <Typography
                                sx={{
                                  textDecoration: "underline",
                                  textDecorationColor: "#3546ab",
                                  padding: "5px 0",
                                  wordSpacing: "0.16rem",
                                }}
                              >
                                {item.text}
                              </Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                case "paragraph":
                  return (
                    <>
                      <h2
                        id={item.id}
                        className="text-readability"
                        style={{ padding: "0 0 1vh" }}
                      >
                        {item.heading}
                      </h2>
                      {item.text.map((item) => {
                        return (
                          <Typography
                            className="text-readability"
                            padding="0 0 23px"
                            variant="body1"
                          >
                            {item.text}
                          </Typography>
                        );
                      })}
                    </>
                  );
                case "image":
                  return (
                    <Box padding="32px 0 0">
                      <img src={item?.url} alt={item?.altText} title={item?.altText} width="100%" loading="lazy" />

                      <Typography
                        color="#121416"
                        fontSize={{ xs: "10px", md: "14px", lg: "16px" }}
                        lineHeight="27px"
                        fontFamily="Inter"
                      >
                        {item?.source}
                      </Typography>
                    </Box>
                  );
                case "key-takeaway":
                  return (
                    <Box
                      bgcolor="#f0f8ff"
                      padding="30px 30px"
                      margin="32px 0 50px"
                    >
                      <Typography
                        fontSize="18px"
                        fontWeight="600"
                        padding="0 0 1vh"
                      >
                        Key Takeaway:
                      </Typography>
                      <Typography
                        fontSize="18px"
                        fontWeight="400"
                        lineHeight="38px"
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  );
                default:
                  break;
              }
            })}
          </Box>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding={{ xs: "0 2vw 10vh", md: "0 10vw 10vh" }}
        >
          <Typography
            color="#6373CF"
            fontSize="16px"
            fontWeight="600"
            fontFamily="Inter"
            pb="5vh"
          >
            MEET THE AUTHOR
          </Typography>
          <Grid container borderRadius="30px" bgcolor="#F3F5F6">
            <Grid item xs={12} md={4} padding={{ xs: "2vh 2vw 5vh", md: "0" }}>
              {blogs.authorImgLarge ? (
                <img
                  src={blogs.authorImgLarge}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "auto",
                    display: "block",
                    margin: "auto",
                    borderRadius: "30px",
                  }}
                />
              ) : (
                <img
                  src="/assets/images/userimg.png"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "auto",
                    display: "block",
                    margin: "auto",
                    borderRadius: "30px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                padding="0 50px"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={1}
              >
                <Typography
                  color="#170F49"
                  fontSize="34px"
                  fontWeight="700"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.author}
                </Typography>
                <Typography
                  color="#6373CF"
                  fontSize="18px"
                  fontWeight="600"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.authorDesignation}
                </Typography>
                <Typography
                  color="#6F6C90"
                  fontSize="18px"
                  fontWeight="400"
                  fontFamily="Inter"
                  textAlign={{ xs: "center", md: "left" }}
                >
                  {blogs.authorMessage}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", md: "start" }}
                  sx={{ translate: { xs: "0", md: "-10px 0" } }}
                >
                  <IconButton href={blogs.authorSocialLinks?.linkedIn}>
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
