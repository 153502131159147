import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "./scss/Policies.css";
import { HashLink } from "react-router-hash-link";
import Footer from "../../components/ui-components/Footer";
import Navbar from "../../components/ui-components/Navbar";
import { Link } from "react-router-dom";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Grid
        item
        container
        xs={12}
      >
        <Navbar currentPage="landing-page" />
      </Grid>
      <Typography
        variant="h1"
        sx={{
          letterSpacing: 3,
          fontSize: { xs: "35px", md: "50px" },
          color: "#000",
          padding: "0 0 2vh",
        }}
      >
        Privacy Policy
      </Typography>
      {/* <Subheader heading="Privacy  Policy" /> */}
      <Grid container className="policybody">
        <Grid item lg={12}>
          <Typography variant="body1">
            <b>Last updated:</b> January 20, 2024
          </Typography>
          <br />

          <Typography variant="body1">
            Thank you for choosing to be part of our community at Catch U There
            Pty Ltd (ABN 72 638 191 885) ("Company", "we", "us", "our"). We are
            committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about this privacy
            notice, or our practices with regards to your personal information,
            please contact us at hello@catchuthere.com.
          </Typography>
          <br />
          <Typography variant="body1">
            When you visit our website http://www.catchuthere.com (the
            "Website"), and more generally, use any of our services (the
            "Services", which include the Website), we appreciate that you are
            trusting us with your personal information. We take your privacy
            very seriously. In this privacy notice, we seek to explain to you in
            the clearest way possible what information we collect, how we use it
            and what rights you have in relation to it. We hope you take some
            time to read through it carefully, as it is important. If there are
            any terms in this privacy notice that you do not agree with, please
            discontinue use of our Services immediately.
          </Typography>
          <br />
          <Typography variant="body1">
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </Typography>
          <br />
          <Typography variant="body1">
            <b>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </b>
          </Typography>
          <br />
          <Typography variant="body1"></Typography>
          <br />

          {/* table of content */}

          <Typography variant="h5">TABLE OF CONTENTS</Typography>
          <ol className="table-content">
            <li>
              <HashLink smooth to="#1">
                WHAT INFORMATION DO WE COLLECT?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#2">
                HOW DO WE USE YOUR INFORMATION?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#3">
                WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#4">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#5">
                DO WE USE GOOGLE MAPS PLATFORM APIS?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#6">
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#7">
                HOW DO WE KEEP YOUR INFORMATION SAFE?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#8">
                WHAT ARE YOUR PRIVACY RIGHTS?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#9">
                CONTROLS FOR DO-NOT-TRACK FEATURES
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#10">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#11">
                DO WE MAKE UPDATES TO THIS NOTICE?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#12">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="#13">
                HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </HashLink>
            </li>
          </ol>

          <br />

          <Typography variant="h5" id="1">
            1. WHAT INFORMATION DO WE COLLECT?
          </Typography>
          <br />
          <Typography variant="h6">
            Personal information you disclose to us
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We collect personal information that you provide to
            us.
          </Typography>
          <Typography variant="body1">
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Website or otherwise when you contact us.
          </Typography>
          <br />
          <Typography variant="body1">
            The personal information that we collect depends on the context of
            your interactions with us and the Website, the choices you make and
            the products and features you use. The personal information we
            collect may include the following:
          </Typography>
          <br />

          <Typography variant="body1">
            <b>Personal Information Provided by You:</b> We collect email
            addresses; names; and other similar information.
          </Typography>

          <br />

          <Typography variant="body1">
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </Typography>
          <br />

          <Typography variant="h6">
            Information automatically collected
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b>Some information — such as your Internet Protocol
            (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Website.
          </Typography>
          <br />

          <Typography variant="body1">
            We automatically collect certain information when you visit, use or
            navigate the Website. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our Website and other technical information.
            This information is primarily needed to maintain the security and
            operation of our Website, and for our internal analytics and
            reporting purposes.
            <br />
            <br />
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </Typography>
          <br />

          <Typography variant="body1">
            The information we collect includes:
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="body1">
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage and performance information our servers
                automatically collect when you access or use our Website and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type and settings and information about
                your activity in the Website (such as the date/time stamps
                associated with your usage, pages and files viewed, searches and
                other actions you take such as which features you use), device
                event information (such as system activity, error reports
                (sometimes called 'crash dumps') and hardware settings).
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                Location Data. We collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Website. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. Note however, if
                you choose to opt out, you may not be able to use certain
                aspects of the Services.
              </Typography>
            </li>
          </ul>

          <br />
          <Typography variant="h5" id="2">
            2. HOW DO WE USE YOUR INFORMATION?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </Typography>
          <br />
          <Typography variant="body1">
            We use personal information collected via our Website for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </Typography>
          <br />

          <Typography variant="body1">
            We use the information we collect or receive:
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="body1">
                <b> To send you marketing and promotional communications.</b> We
                and/or our third-party marketing partners may use the personal
                information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. For example,
                when expressing an interest in obtaining information about us or
                our Website, subscribing to marketing or otherwise contacting
                us, we will collect personal information from you. You can
                opt-out of our marketing emails at any time (see the "WHAT ARE
                YOUR PRIVACY RIGHTS" below).
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                <b>Deliver targeted advertising to you.</b> We may use your
                information to develop and display personalized content and
                advertising (and work with third parties who do so) tailored to
                your interests and/or location and to measure its effectiveness.
              </Typography>
            </li>
          </ul>
          <br />

          <Typography variant="h5" id="3">
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We only share information with your consent, to
            comply with laws, to provide you with services, to protect your
            rights, or to fulfill business obligations.
          </Typography>
          <br />
          <Typography variant="body1">
            We may process or share your data that we hold based on the
            following legal basis:
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="body1">
                <b> Consent:</b> We may process your data if you have given us
                specific consent to use your personal information for a specific
                purpose.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                <b>Legitimate Interests: </b>We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                <b>Performance of a Contract:</b> Where we have entered into a
                contract with you, we may process your personal information to
                fulfill the terms of our contract.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                <b>Legal Obligations: </b> We may disclose your information
                where we are legally required to do so in order to comply with
                applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court
                order or a subpoena (including in response to public authorities
                to meet national security or law enforcement requirements).
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant="body1">
                <b>Vital Interests:</b> We may disclose your information where
                we believe it is necessary to investigate, prevent, or take
                action regarding potential violations of our policies, suspected
                fraud, situations involving potential threats to the safety of
                any person and illegal activities, or as evidence in litigation
                in which we are involved.
              </Typography>
            </li>
            <br />
          </ul>

          <Typography variant="body1">
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="body1">
                <b> Business Transfers.</b> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </Typography>
            </li>
            <br />
          </ul>

          <br />
          <Typography variant="h5" id="4">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We may use cookies and other tracking technologies
            to collect and store your information.
          </Typography>
          <br />
          <Typography variant="body1">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </Typography>

          <Typography variant="h5" id="5">
            5. DO WE USE GOOGLE MAPS PLATFORM APIS?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> Yes, we use Google Maps Platform APIs for the
            purpose of providing better service.
          </Typography>
          <br />
          <Typography variant="body1">
            This Website uses Google Maps Platform APIs which are subject to
            Google’s Terms of Service. You may find the Google Maps Platform
            Terms of Service here. To find out more about Google’s Privacy
            Policy, please refer to this link.
          </Typography>

          <br />
          <Typography variant="h5" id="6">
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We keep your information for as long as necessary
            to fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </Typography>
          <br />
          <Typography variant="body1">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than 2 years.
            <br />
            <br />
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </Typography>

          <br />
          <Typography variant="h5" id="7">
            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> We aim to protect your personal information through
            a system of organizational and technical security measures.
          </Typography>
          <br />
          <Typography variant="body1">
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from our Website is at your own risk. You should only access the
            Website within a secure environment.
          </Typography>

          <br />

          <Typography variant="h5" id="8">
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> In some regions, such as the European Economic
            Area, you have rights that allow you greater access to and control
            over your personal information. You may review, change, or terminate
            your account at any time.
          </Typography>
          <br />
          <Typography variant="body1">
            In some regions (like the European Economic Area), you have certain
            rights under applicable data protection laws. These may include the
            right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to
            restrict the processing of your personal information; and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to the processing of your personal
            information. To make such a request, please use the contact details
            provided below. We will consider and act upon any request in
            accordance with applicable data protection laws.
            <br />
            <br />
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing before its withdrawal, nor will it affect the
            processing of your personal information conducted in reliance on
            lawful processing grounds other than consent.
            <br />
            <br />
            If you are a resident in the European Economic Area and you believe
            we are unlawfully processing your personal information, you also
            have the right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            <br />
            <br />
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:
            https://www.edoeb.admin.ch/edoeb/en/home.html.
            <br />
            <br />
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies. If you
            choose to remove cookies or reject cookies, this could affect
            certain features or services of our Website. To opt-out of
            interest-based advertising by advertisers on our Website visit
            http://www.aboutads.info/choices/.
          </Typography>

          <br />
          <Typography variant="h5" id="9">
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </Typography>
          <br />

          <Typography variant="body1">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </Typography>

          <br />
          <Typography variant="h5" id="10">
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b>Yes, if you are a resident of California, you are
            granted specific rights regarding access to your personal
            information.
          </Typography>
          <br />
          <Typography variant="body1">
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
            <br />
            <br />
            If you are under 18 years of age, reside in California, and have a
            registered account with the Website, you have the right to request
            removal of unwanted data that you publicly post on the Website. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Website,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
            <br />
          </Typography>
          <br />

          <Typography variant="h6">CCPA Privacy Notice</Typography>
          <br />
          <Typography variant="body1">
            The California Code of Regulations defines a "resident" as:
          </Typography>
          <br />
          <Typography variant="body1">
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
            <br />
            <br />
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
            <br />
            <br />
            All other individuals are defined as "non-residents."
            <br />
            <br />
            If this definition of "resident" applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </Typography>
          <br />

          <Typography variant="h6">
            What categories of personal information do we collect?
          </Typography>
          <br />
          <Typography variant="body1">
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </Typography>
          <br />

          <table className="table">
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
            <tr>
              <td>
                <Typography variant="body1">A. Identifiers</Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address and account name
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  B. Personal information categories listed in the California
                  Customer Records statute
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Name, contact information, education, employment, employment
                  history and financial information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">YES</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  C. Protected classification characteristics under California
                  or federal law
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Gender and date of birth
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  D. Commercial information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Transaction information, purchase history, financial details
                  and payment information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  E. Biometric information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Fingerprints and voiceprints
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  F. Internet or other similar network activity
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems and advertisements
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">G. Geolocation data</Typography>
              </td>
              <td>
                <Typography variant="body1">Device location</Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  I. Professional or employment-related information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Business contact details in order to provide you our services
                  at a business level, job title as well as work history and
                  professional qualifications if you apply for a job with us
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  J. Education Information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Student records and directory information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body1">
                  K. Inferences drawn from other personal information
                </Typography>
              </td>
              <td>
                <Typography variant="body1">
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </Typography>
              </td>
              <td>
                <Typography variant="body1">NO</Typography>
              </td>
            </tr>
          </table>

          <br />
          <Typography variant="body1">
            We may also collect other personal information outside of these
            categories instances where you interact with us in-person, online,
            or by phone or mail in the context of:
          </Typography>
          <br />

          <ul>
            <li>
              <Typography variant="body1">
                Receiving help through our customer support channels;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Participation in customer surveys or contests; and
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </Typography>
            </li>
          </ul>

          <Typography variant="h6">
            How do we use and share your personal information?
          </Typography>
          <br />
          <Typography variant="body1">
            More information about our data collection and sharing practices can
            be found in this privacy notice. You may contact us by email at
            hello@catchuthere.com, or by referring to the contact details at the
            bottom of this document.
            <br />
            <br />
            If you are using an authorized agent to exercise your right to
            opt-out we may deny a request if the authorized agent does not
            submit proof that they have been validly authorized to act on your
            behalf.
            <br />
            <br />
            <br />
          </Typography>
          <br />

          <Typography variant="h6">
            Will your information be shared with anyone else?
          </Typography>
          <br />
          <Typography variant="body1">
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf.
            <br />
            <br />
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            "selling" of your personal data.
            <br />
            <br />
            Catch U There Pty Ltd (ABN 72 638 191 885) has not disclosed or sold
            any personal information to third parties for a business or
            commercial purpose in the preceding 12 months. Catch U There Pty Ltd
            (ABN 72 638 191 885) will not sell personal information in the
            future belonging to website visitors, users and other consumers.
          </Typography>
          <br />

          <Typography variant="h6">
            Your rights with respect to your personal data
          </Typography>
          <br />
          <Typography variant="body1">
            <u>Right to request deletion of the data - Request to delete</u>{" "}
          </Typography>
          <br />
          <Typography variant="body1">
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation or any
            processing that may be required to protect against illegal
            activities.
          </Typography>

          <Typography variant="body1">
            <u>Right to be informed - Request to know</u>{" "}
          </Typography>
          <br />

          <Typography variant="body1">
            Depending on the circumstances, you have a right to know:
          </Typography>

          <ul>
            <li>
              <Typography variant="body1">
                whether we collect and use your personal information;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                the categories of personal information that we collect;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                the purposes for which the collected personal information is
                used;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                whether we sell your personal information to third parties;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                the categories of personal information that we sold or disclosed
                for a business purpose;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                the categories of third parties to whom the personal information
                was sold or disclosed for a business purpose; and
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                the business or commercial purpose for collecting or selling
                personal information.
              </Typography>
            </li>
          </ul>

          <Typography variant="body1">
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </Typography>

          <Typography variant="body1">
            <u>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </u>{" "}
          </Typography>
          <br />

          <Typography variant="body1">
            We will not discriminate against you if you exercise your privacy
            rights.
          </Typography>
          <br />

          <Typography variant="body1">
            <u>Verification process</u>{" "}
          </Typography>
          <br />

          <Typography variant="body1">
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
            <br />
            <br />
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. If, however, if we cannot verify
            your identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity, and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </Typography>
          <br />

          <Typography variant="body1">
            <u>Other privacy rights</u>{" "}
          </Typography>
          <br />

          <ul>
            <li>
              <Typography variant="body1">
                you may object to the processing of your personal data
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                you may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the data
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                you can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                you may request to opt-out from future selling of your personal
                information to third parties. Upon receiving a request to
                opt-out, we will act upon the request as soon as feasibly
                possible, but no later than 15 days from the date of the request
                submission.
              </Typography>
            </li>
          </ul>

          <Typography variant="body1">
            To exercise these rights, you can contact us by email at
            hello@catchuthere.com, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </Typography>
          <br />

          <Typography variant="h5" id="11">
            11. DO WE MAKE UPDATES TO THIS NOTICE?{" "}
          </Typography>
          <br />
          <Typography variant="body1">
            <b>In Short:</b> Yes, we will update this notice as necessary to
            stay compliant with relevant laws.
          </Typography>
          <br />
          <Typography variant="body1">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </Typography>
          <br />

          <Typography variant="h5" id="12">
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
          </Typography>
          <br />
          <Typography variant="body1">
            If you have questions or comments about this notice, you may email
            us at hello@catchuthere.com or by post to:
          </Typography>
          <br />
          <Typography variant="body1">
            Catch U There Pty Ltd (ABN 72 638 191 885)
            <br />
            Level 8 of 121 Marcus Clarke Street
            <br />
            Canberra, Australian Capital Territory 2601
            <br />
            Australia
          </Typography>
          <br />

          <Typography variant="h5" id="13">
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?{" "}
          </Typography>
          <br />
          <Typography variant="body1">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request form by{" "}
            <Link
              to="/contact-us"
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              clicking here
            </Link>
            . We will respond to your request within 30 days.
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Footer currentPage="privacy" />
    </div>
  );
}
