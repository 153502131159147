import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

export default function ExploreSectionCard(props) {
    const {item} = props;
  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={4}
      display="flex"
      justifyContent="center"
      padding="0 20px"
    >
      <Link to={`/explore/${item.location.toLowerCase().replace(/\s+/g, '-')}/${item.url_slug}`}>
        <Card
          sx={{
            boxShadow: "0 0.5px 1px gray",
            borderRadius: "25px",
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: { xs: "430px", lg: "460px" },
            width: { xs: "290px", lg: "315px" },
            transition: "transform 0.3s, boxShadow 0.3s",
            ":hover": {
              transform: "scale(1.1)",
              boxShadow: "0 3px 2px gray",
            },
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: { xs: "360px", lg: "390px" } }}
            image={item.bannerImg.url_small}
            alt={item.bannerImg.altText}
            loading="lazy"
          />
          <Typography
            color="#5E6282"
            fontSize="18px"
            margin="10px 20px 30px"
          >
            {item.title}
          </Typography>
        </Card>
      </Link>
    </Grid>
  );
}
