import React, { useEffect } from "react";
import BlogsComponent from "./BlogsComponent";
import { useParams } from "react-router-dom";
import Grid from '@mui/material/Grid';
import blogsData from "./blogsData";
import Navbar from "../../components/ui-components/Navbar";
import Footer from "../../components/ui-components/Footer";

export default function Explore() {
  const params = useParams();
  const { blogid } = params;
  const blogs = blogsData.find((b) => b.url_slug == blogid);
  console.log(blogs,'kya h')
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(/assets/images/litineraryback.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Navbar currentPage = 'blogs' />
      </Grid>
      <BlogsComponent blogs={blogs} />
      <Footer/>
    </>
  );
}
