import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import blogsData from "../../pages/Blogs/blogsData";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Pagination } from "swiper/modules";
import ExploreSectionCard from "./ExploreSectionCard";

export default function ExploreSection(props) {
  const { currentPage } = props;

  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");

  return (
    <Box
      padding={{ xs: "0 10vw", md: "0 7vw", lg: "0 10vw" }}
      marginBottom={{ xs: "7vh", md: "7vh", lg: "10vh" }}
    >
      <Typography
      className="heading"
        textAlign="center"
        variant="h1"
        fontSize={{ xs: "28px", md: "40px", lg: "50px" }}
        marginBottom="5vh"
        fontWeight="bold"
      >
        Explore
      </Typography>

      <Grid container display="flex" justifyContent="space-between">
        {isLargeScreen ? (
          blogsData.filter(item => [1, 2, 3].includes(item.id)).map(item => <ExploreSectionCard item={item} />)
        ) : (
          <Swiper
            style={{ paddingBottom: "50px", width:'100%' }}
            slidesPerView={isMediumScreen ? 2 : 1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {blogsData.filter(item => [1, 2, 3].includes(item.id)).map(item => <SwiperSlide><ExploreSectionCard item={item} /></SwiperSlide>)}
          </Swiper>
        )}
          <Grid
            item
            xs={12}
            display="flex"
            paddingTop={{ xs: "3vh", lg: "6vh" }}
            justifyContent={{ xs: "center", md: "center", lg: "center" }}
            paddingLeft={{ xs: "0", md: "0", lg: "0" }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#6373CF",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Inter",
              }}
              href="/explore"
            >
              See More <ChevronRightIcon />
            </Button>
          </Grid>
      </Grid>
    </Box>
  );
}
