import React, { useRef, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MuiAlert from "@mui/material/Alert";
import "../ui-components/scss/content-section-carousel.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import blogsData from "../../pages/Blogs/blogsData";
import ExploreSection from "./ExploreSection";

// subscription alert message
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ContentSection(props) {
  const { currentPage } = props;
  // screen size mediquery hook
  let isLargeScreen = useMediaQuery("(min-width:992px)");
  let isMediumScreen = useMediaQuery("(min-width:768px)");

  // message for Subscribe button
  const [open, setOpen] = React.useState(false);

  // emial validation here
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");

  // checkbox function
  const [checked, setChecked] = React.useState(true);
  const [checked1, setChecked1] = React.useState(false);

  let sendMail = () => {
    const apiKey = process.env.REACT_APP_EMAIL_KEY;
    const emailData = {
      email: email,
    };
    axios
      .post("https://api.brevo.com/v3/contacts", emailData, {
        headers: {
          "api-key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEmailAlert(response.status);
        console.log(response.status,'checkkkkkkkkkkkkkk');
      })
      .catch((error) => {
        setEmailAlert(error.response.status);
        console.error(error.response.data.message);
        console.log(error,'checkkkkkkkkkkkkkk');
      });
  };

  // checkbox function
  const containerRef = useRef(null);

  let toggleClass = () => {
    let element = document.getElementById("page1");
    let containsSlide1 = element.classList.contains("slide1");
    if (containsSlide1) {
      document.getElementById("page1").className = "slide2";
      document.getElementById("page2").className = "slide1";
    } else {
      document.getElementById("page1").className = "slide1";
      document.getElementById("page2").className = "slide2";
    }
  };

  // emial validation here
  function handleEmailChange(event) {
    const { value } = event.target;
    setEmail(value);
  }
  const emailData = {
    email: email,
  };
  function handleSubmit(event) {
    event.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setEmailAlert("Invalid email format");
    } else {
      setEmailAlert(""); // Clear any previous error messages
      // Continue with the form submission or further processing
    }
  }

  // message for Subscribe button

  const handleClick = () => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setEmailAlert("Invalid email format");
    } else {
      setEmailAlert(""); // Clear any previous error messages

      // Call sendMail and wait for it to complete
      sendMail().then((success) => {
        if (success) {
          setOpen(true);
        }
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const travelPlanCards = [
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      padding="50px 20px 0px"
      display="flex"
      justifyContent="center"
    >
      <Card
        sx={{
          boxShadow: "0 0.5px 1px gray",
          borderRadius: "25px",
          borderRadius: "25px",
          padding: {
            xs: "20px 20px 35px",
            md: "20px 20px 35px",
            lg: "20px 20px 35px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "320px", md: "370px", lg: "370px" },
          width: { xs: "270px", md: "270px", lg: "300px" },
          transition: "transform 0.3s, boxShadow 0.3s",
          ":hover": {
            transform: "scale(1.1)",
            boxShadow:
              "0px 100px 80px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.00)",
          },
        }}
      >
        <img src="assets/images/budget.svg" width="80px" height='80px' />
        <Typography marginTop="20px" fontWeight="600" fontSize="20px">
          Budget
        </Typography>
        <Typography
          color="#5E6282"
          textAlign="center"
          marginTop="20px"
          fontSize={{ xs: "14px", md: "14px", lg: "16px" }}
          fontWeight="400"
          lineHeight="28px"
          maxWidth="225px"
        >
          Use our budget planner to easily track your bookings and estimate the
          cost of your trips. Manage and organize all your travel expenses in
          one place while on the go.
        </Typography>
      </Card>
    </Grid>,
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      padding="50px 20px 0px"
      display="flex"
      justifyContent="center"
    >
      <Card
        sx={{
          boxShadow: "0 0.5px 1px gray",
          borderRadius: "25px",
          padding: {
            xs: "20px 20px 35px",
            md: "20px 20px 35px",
            lg: "20px 20px 35px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "320px", md: "370px", lg: "370px" },
          width: { xs: "270px", md: "270px", lg: "300px" },
          transition: "transform 0.3s, boxShadow 0.3s",
          ":hover": {
            transform: "scale(1.1)",
            boxShadow:
              "0px 100px 80px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.00)",
          },
        }}
      >
        <img
          src="assets/images/search.svg"
          width="80px"
          height='80px'
        />
        <Typography marginTop="20px" fontWeight="600" fontSize="20px">
          Search
        </Typography>
        <Typography
          color="#5E6282"
          textAlign="center"
          marginTop="20px"
          fontSize={{ xs: "14px", md: "14px", lg: "16px" }}
          fontWeight="400"
          lineHeight="28px"
          maxWidth="225px"
        >
          Catch U There helps you find the best deals on flights, hotels, and
          car hire. Search, compare, save for later, and share your favorite
          options with friends—all in one app.
        </Typography>
      </Card>
    </Grid>,
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      padding="50px 20px 0px"
      display="flex"
      justifyContent="center"
    >
      <Card
        sx={{
          boxShadow: "0 0.5px 1px gray",
          borderRadius: "25px",
          borderRadius: "25px",
          padding: {
            xs: "20px 20px 35px",
            md: "20px 20px 35px",
            lg: "20px 20px 35px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "320px", md: "370px", lg: "370px" },
          width: { xs: "270px", md: "270px", lg: "300px" },
          transition: "transform 0.3s, boxShadow 0.3s",
          ":hover": {
            transform: "scale(1.1)",
            boxShadow:
              "0px 100px 80px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.00)",
          },
        }}
      >
        <img src="/assets/images/creative.svg" width="80px" height="80px" />
        <Typography marginTop="20px" fontWeight="600" fontSize="20px">
          Create
        </Typography>
        <Typography
          color="#5E6282"
          textAlign="center"
          marginTop="20px"
          fontSize={{ xs: "14px", md: "14px", lg: "16px" }}
          fontWeight="400"
          lineHeight="28px"
          maxWidth="225px"
        >
          Create custom itineraries with our easy-to-use tools. As you book,
          search, and monitor price changes, narrow down your options and share
          detailed plans effortlessly.
        </Typography>
      </Card>
    </Grid>,
    <Grid
      item
      xs={12}
      md={12}
      lg={3}
      padding="50px 20px 0px"
      display="flex"
      justifyContent="center"
    >
      <Card
        sx={{
          boxShadow: "0 0.5px 1px gray",
          borderRadius: "25px",
          borderRadius: "25px",
          padding: {
            xs: "20px 20px 35px",
            md: "20px 20px 35px",
            lg: "20px 20px 35px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "320px", md: "370px", lg: "370px" },
          width: { xs: "270px", md: "270px", lg: "300px" },
          transition: "transform 0.3s, boxShadow 0.3s",
          ":hover": {
            transform: "scale(1.1)",
            boxShadow:
              "0px 100px 80px 0px rgba(0, 0, 0, 0.02), 0px 64.815px 46.852px 0px rgba(0, 0, 0, 0.02), 0px 38.519px 25.481px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 8.148px 6.519px 0px rgba(0, 0, 0, 0.01), 0px 1.852px 3.148px 0px rgba(0, 0, 0, 0.00)",
          },
        }}
      >
        <img
          src="/assets/images/plan.svg"
          style={{ marginLeft: "23px" }}
          width="80px"
          height="80px"
        />
        <Typography marginTop="20px" fontWeight="600" fontSize="20px">
          Plan
        </Typography>
        <Typography
          color="#5E6282"
          textAlign="center"
          marginTop="20px"
          fontSize={{ xs: "14px", md: "14px", lg: "16px" }}
          fontWeight="400"
          lineHeight="28px"
          maxWidth="225px"
        >
          Save your favorite hotels, flights, and attractions in individual trip
          folders for easy access. Revisit and update your plans anytime, adding
          items to your itineraries as you go.
        </Typography>
      </Card>
    </Grid>,
  ];

  return (
    <>
      <Box
        padding={{ xs: "0 10vw", md: "0 7vw", lg: "0 7vw" }}
        marginBottom={{ xs: "7vh", md: "7vh", lg: "10vh" }}
      >
        <Typography
          className="heading"
          variant="h1"
          textAlign="center"
          fontSize={{ lg: "50px", xs: "28px" }}
          fontWeight="bold"
          margin={{ xs: "5vh 0 0", md: "5vh 0 0", lg: "10vh 0 0 " }}
        >
          Travel Planning Made Easy
        </Typography>
        <Grid container display="flex" justifyContent="space-between">
          {isLargeScreen ? (
            travelPlanCards
          ) : (
            <Swiper
              style={{ paddingBottom: "50px", width: "100%" }}
              slidesPerView={isMediumScreen ? 2 : 1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {travelPlanCards.map((item) => (
                <SwiperSlide>{item}</SwiperSlide>
              ))}
            </Swiper>
          )}
        </Grid>
      </Box>

      <ExploreSection currentPage={currentPage} />

      <Box
        padding={{ xs: "0 10vw", md: "0 7vw", lg: "0 10vw" }}
        marginBottom={{ xs: "7vh", md: "7vh", lg: "10vh" }}
      >
        <Grid container flexWrap={{ xs: "wrap-reverse", md: "wrap" }}>
          <Grid item xs={12} md={6}>
            <Typography
              className="heading"
              variant="h1"
              fontSize={{ xs: "28px", md: "35px", lg: "50px" }}
              fontWeight="700"
              marginBottom="5vh"
              width={{ xs: "auto", lg: "490px" }}
              textAlign={{ xs: "center", md: "left", lg: "left" }}
            >
              Plan Your Next Trip With Catch U There
            </Typography>

            <Grid container marginBottom={{ xs: "3vh", lg: "7vh" }}>
              <Grid item xs={2.5} lg={1.5} mt="4px">
                <Box
                  bgcolor="#6373CF"
                  borderRadius="13px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="52px"
                  height="48px"
                >
                  <AccessTimeIcon sx={{ color: "white" }} />
                </Box>
              </Grid>
              <Grid item xs={9.5} lg={10.5}>
                <Typography color="#5E6282" fontWeight="700" fontSize="16px">
                  Quick and Easy
                </Typography>
                <Typography
                  color="#5E6282"
                  fontWeight="400"
                  fontSize="16px"
                  width={{ xs: "auto", lg: "360px" }}
                >
                  Quick and easy, just add destinations & dates, and let Catch U
                  There do the work{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid container marginBottom={{ xs: "3vh", lg: "7vh" }}>
              <Grid item xs={2.5} lg={1.5} mt="4px">
                <Box
                  bgcolor="#E44E4E"
                  borderRadius="13px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="52px"
                  height="48px"
                >
                  <img src="/assets/images/collaborate-and-share.svg" />
                </Box>
              </Grid>
              <Grid item xs={9.5} lg={10.5}>
                <Typography color="#5E6282" fontWeight="700" fontSize="16px">
                  Create, Collaborate and Share
                </Typography>
                <Typography
                  color="#5E6282"
                  fontWeight="400"
                  fontSize="16px"
                  width={{ xs: "auto", lg: "360px" }}
                >
                  Create, collaborate and share custom itineraries with others.{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2.5} lg={1.5} mt="4px">
                <Box
                  bgcolor="#F2A818"
                  borderRadius="13px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="52px"
                  height="48px"
                >
                  <img src="/assets/images/save-time.svg" />
                </Box>
              </Grid>
              <Grid item xs={9.5} lg={10.5}>
                <Typography color="#5E6282" fontWeight="700" fontSize="16px">
                  Save time and Money
                </Typography>
                <Typography
                  color="#5E6282"
                  fontWeight="400"
                  fontSize="16px"
                  width={{ xs: "auto", lg: "360px" }}
                >
                  Save time and money with improved price predictions
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={{ xs: "0 0 5vh" }}
          >
            <img src="/assets/images/image34.webp" width="100%" loading="lazy" />
          </Grid>
        </Grid>
      </Box>

      <Box
        padding={{ xs: "0 10vw", md: "0 7vw", lg: "0 10vw" }}
        marginBottom={{ xs: "7vh", md: "7vh", lg: "10vh" }}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              color="#5E6282"
              fontSize={{ xs: "16px", md: "18px" }}
              fontWeight="600"
              fontFamily="sans-serif"
            >
              OUR MISSION
            </Typography>
            <Typography
              className="heading"
              variant="h1"
              textAlign="start"
              fontSize={{ xs: "28px", md: "35px", lg: "50px" }}
              fontWeight="600"
              marginBottom={{ xs: "1vh", md: "1vh", lg: "5vh" }}
            >
              Revolutionizing<br/>Travel Planning
            </Typography>
          </Grid>

          <Grid item container xs={12} md={12} lg={6}>
            <Grid item xs={12} md={11} lg={9}>
              <Box
                height="306px"
                className="container"
                marginTop={{ xs: "5vh", lg: "0" }}
              >
                <Box
                  width="100%"
                  id="page1"
                  className="slide1"
                  borderRadius="10px"
                >
                  <Card
                    sx={{
                      height: "auto",
                      position: "relative",
                      overflow: "visible",
                      padding: "30px",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <Box
                      position="absolute"
                      top="-40px"
                      left={{ lg: "-40px", xs: "30px" }}
                    >
                      <img
                        src="/assets/images/co-founder.png"
                        width="70px"
                        height="70px"
                      />
                    </Box>
                    <Typography
                      color="#5E6282"
                      fontWeight="600"
                      fontFamily="sans-serif"
                      fontSize={{ xs: "14px", lg: "16px" }}
                      paddingTop={{ xs: "15px", lg: "0" }}
                    >
                      “The ultimate aim is for Catch U There to become a
                      one-stop shop for planning, booking and travel
                      inspiration.”
                    </Typography>
                    <Typography
                      color="#5E6282"
                      paddingTop="2vh"
                      fontSize="18px"
                      fontWeight="600"
                    >
                      Josh Walton
                    </Typography>
                    <Typography
                      color="#5E6282"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      Co-founder
                    </Typography>
                  </Card>
                </Box>

                {/* paper 2 */}
                <Box
                  width="100%"
                  id="page2"
                  className="slide2"
                  borderRadius="10px"
                >
                  <Card
                    sx={{
                      position: "relative",
                      overflow: "visible",
                      padding: "30px",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <Box
                      position="absolute"
                      top="-40px"
                      left={{ lg: "-40px", xs: "30px" }}
                    >
                      <img
                        src="/assets/images/co-founder-naeem.jpg"
                        width="70px"
                        height="70px"
                        style={{ borderRadius: "100%" }}
                      />
                    </Box>
                    <Typography
                      color="#5E6282"
                      fontWeight="600"
                      fontFamily="sans-serif"
                      fontSize={{ lg: "16px", xs: "14px" }}
                      paddingTop={{ lg: "0", xs: "15px" }}
                    >
                      “The Catch U There team wants to turn the travel-planning
                      and booking process upside down and become a global travel
                      brand.”
                    </Typography>
                    <Typography
                      color="#5E6282"
                      paddingTop="2vh"
                      fontSize="18px"
                      fontWeight="600"
                    >
                      Naeem Ashraf
                    </Typography>
                    <Typography
                      color="#5E6282"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      Co-founder
                    </Typography>
                  </Card>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Box
                paddingLeft={{ xs: "0", md: "5vh", lg: "10vh" }}
                display="flex"
                height="100%"
                flexDirection={{ xs: "row", md: "column", lg: "column" }}
                justifyContent={{
                  xs: "flex-start",
                  md: "center",
                  lg: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <IconButton onClick={toggleClass}>
                      {isMediumScreen ? (
                        <KeyboardArrowUpIcon fontSize="large" />
                      ) : (
                        <ArrowBackIosNewIcon />
                      )}
                    </IconButton>
                  }
                />
                <FormControlLabel
                  control={
                    <IconButton onClick={toggleClass}>
                      {isMediumScreen ? (
                        <KeyboardArrowDownIcon fontSize="large" />
                      ) : (
                        <ArrowForwardIosIcon />
                      )}
                    </IconButton>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        id="subscribe"
        padding={{ xs: "0 10vw", md: "0 7vw", lg: "0 10vw" }}
        marginBottom={{ xs: "7vh", md: "7vh", lg: "10vh" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          bgcolor="#F9F7FE"
          padding="0 15vw"
          borderRadius="129px 20px 20px 20px"
          position="relative"
          marginBottom={{ lg: "10vh", xs: "5vh" }}
        >
          <Box
            position="absolute"
            top="-25px"
            right="-25px"
            borderRadius="50%"
            sx={{
              background:
                "linear-gradient(201.65deg, #8861D4 10.27%, #5E3BE1 100%)",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              width={{ xs: "50px", lg: "70px" }}
              height={{ xs: "50px", lg: "70px" }}
              alignItems="center"
            >
              <EmailIcon fontSize="large" sx={{ color: "white" }} />
            </Box>
          </Box>
          <Box margin={{ xs: "10vh 0", md: "5vh 0", lg: "10vh 0" }}>
            <Typography
              textAlign="center"
              color="#5E6282"
              fontSize={{ xs: "22px", md: "19px", lg: "33px" }}
              margin={{ xs: "5vh 0", md: "2vh 0", lg: "5vh 0" }}
              fontWeight="600"
            >
              Subscribe to get information, latest news and be first to know
              when we go live!
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={10}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={5}
                  bgcolor="white"
                  borderRadius="10px"
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Your email"
                    label=""
                    type="email"
                    onChange={handleEmailChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src="assets/images/image46.svg"
                            width="20px"
                            height="18px"
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& input::placeholder": {
                        fontFamily: "inter",
                        color: "#39425D",
                        fontWeight: "400",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  xs={12}
                  md={3}
                  lg={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{
                      padding: {
                        lg: "2vh 2vw",
                        md: "1vh 2vw",
                        xs: "1vh 2vw",
                      },
                      borderRadius: "10px",
                      marginTop: { lg: 0, md: 0, xs: "2vh" },
                      color: "white",
                      textTransform: "none",
                      fontSize: { xs: "17px", md: "17px", lg: "18px" },
                      backgroundColor: "#6373CF",
                      width: "100%",
                    }}
                  >
                    Subscribe
                  </Button>
                </Grid>
                {emailAlert == "201" ? (
                  <Grid item xs={12} color="#008000">
                  {emailAlert && 'Success! Thanks for subscribing.'}
                </Grid>
                ) : emailAlert == '400' ?(
                  <Grid item xs={12} color="red">
                      Provided email already exist
                  </Grid>
                )
                :
                  <Grid item xs={12} color="red">
                      {emailAlert && emailAlert}
                  </Grid>

              }
              </Grid>
            </form>

            {/* <Stack spacing={2} sx={{ width: "100%" }}>
              {emailAlert == "Contact already exist" ? (
                ""
              ) : (
                <Snackbar
                  open={open}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    {emailAlert}
                  </Alert>
                </Snackbar>
              )}
            </Stack> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}
