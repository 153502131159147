import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3546AB", // Your custom primary color
    },

    // Add more custom colors if needed
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      textAlign: "center",
      fontSize: { xs: "28px", md: "40px", lg: "50px" },
      fontWeight: "bold",
      color: "#14183E",
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Customize the 'xs' breakpoint
      sm: 576, // Customize the 'sm' breakpoint
      md: 768, // Customize the 'md' breakpoint
      lg: 992, // Customize the 'lg' breakpoint
      xl: 1200, // Customize the 'xl' breakpoint
    },
  },
});

export default theme;
