import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import "./scss/Policies.css";
import Navbar from "../../components/ui-components/Navbar";
import Footer from "../../components/ui-components/Footer";

export default function Cookies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Grid
        item
        container
        xs={12}
      >
        <Navbar currentPage="landing-page" />
      </Grid>
      <Typography
        variant="h1"
        sx={{
          letterSpacing: 3,
          fontSize: { xs: "35px", md: "50px" },
          color: "#000",
          padding: "0 0 2vh",
        }}
      >
        Cookie Policy
      </Typography>
      <Grid container className="policybody">
        <Grid item lg={12}>
          <Typography variant="body1">
            <b>Last updated:</b> Last updated January 20, 2024
          </Typography>
          <br />

          <Typography variant="body1">
            This Cookie Policy explains how Catch U There Pty Ltd (ABN 72 638
            191 885) ("Company", "we", "us", and "our") uses cookies and similar
            technologies to recognize you when you visit our websites at
            http://www.catchuthere.com, ("Websites"). It explains what these
            technologies are and why we use them, as well as your rights to
            control our use of them.
            <br />
            <br />
            In some cases we may use cookies to collect personal information, or
            that becomes personal information if we combine it with other
            information.
          </Typography>

          <br />
          <Typography variant="h5">What are cookies?</Typography>
          <br />
          <Typography variant="body1">
            Cookies are small data files that are placed on your computer or
            mobile device when you visit a website. Cookies are widely used by
            website owners in order to make their websites work, or to work more
            efficiently, as well as to provide reporting information.
            <br />
            <br />
            Cookies set by the website owner (in this case, Catch U There Pty
            Ltd (ABN 72 638 191 885)) are called "first party cookies". Cookies
            set by parties other than the website owner are called "third party
            cookies". Third party cookies enable third party features or
            functionality to be provided on or through the website (e.g. like
            advertising, interactive content and analytics). The parties that
            set these third party cookies can recognize your computer both when
            it visits the website in question and also when it visits certain
            other websites.
          </Typography>
          <br />
          <Typography variant="h5">Why do we use cookies?</Typography>
          <br />
          <Typography variant="body1">
            We use first and third party cookies for several reasons. Some
            cookies are required for technical reasons in order for our Websites
            to operate, and we refer to these as "essential" or "strictly
            necessary" cookies. Other cookies also enable us to track and target
            the interests of our users to enhance the experience on our Online
            Properties. Third parties serve cookies through our Websites for
            advertising, analytics and other purposes. This is described in more
            detail below.
            <br />
            <br />
            The specific types of first and third party cookies served through
            our Websites and the purposes they perform are described below
            (please note that the specific cookies served may vary depending on
            the specific Online Properties you visit):
          </Typography>

          <br />
          <Typography variant="h5">How can I control cookies?</Typography>
          <br />
          <Typography variant="body1">
            You have the right to decide whether to accept or reject cookies.
            You can exercise your cookie rights by setting your preferences in
            the Cookie Consent Manager. The Cookie Consent Manager allows you to
            select which categories of cookies you accept or reject. Essential
            cookies cannot be rejected as they are strictly necessary to provide
            you with services.
            <br />
            <br />
            The Cookie Consent Manager can be found in the notification banner
            and on our website. If you choose to reject cookies, you may still
            use our website though your access to some functionality and areas
            of our website may be restricted. You may also set or amend your web
            browser controls to accept or refuse cookies. As the means by which
            you can refuse cookies through your web browser controls vary from
            browser-to-browser, you should visit your browser's help menu for
            more information.
            <br />
            <br />
            In addition, most advertising networks offer you a way to opt out of
            targeted advertising. If you would like to find out more
            information, please visit http://www.aboutads.info/choices/ or
            http://www.youronlinechoices.com.
            <br />
            <br />
            The specific types of first and third party cookies served through
            our Websites and the purposes they perform are described in the
            table below (please note that the specific cookies served may vary
            depending on the specific Online Properties you visit):
          </Typography>
          <br />

          <br />
          <Typography variant="h5">
            <u>Essential website cookies:</u>
          </Typography>
          <br />

          <Typography variant="body1">
            These cookies are strictly necessary to provide you with services
            available through our Websites and to use some of its features, such
            as access to secure areas.
          </Typography>
          <br />
          <div className="sections">
            <Typography variant="body1">Name:__tlbcpv</Typography>
            <Typography variant="body1">
              Purpose:Used to record unique visitor views of the consent banner.
            </Typography>
            <Typography variant="body1">Provider:.termly.io</Typography>
            <Typography variant="body1">
              Service:Termly View Service Privacy Policy{" "}
            </Typography>
            <Typography variant="body1">Country:United States</Typography>
            <Typography variant="body1">Type:http_cookie</Typography>
            <Typography variant="body1">Expires in:1 year</Typography>
            <br />
          </div>

          <br />
          <Typography variant="h5">
            <u>Performance and functionality cookies:</u>
          </Typography>
          <br />
          <Typography variant="body1">
            These cookies are used to enhance the performance and functionality
            of our Websites but are non-essential to their use. However, without
            these cookies, certain functionality (like videos) may become
            unavailable.
          </Typography>
          <br />

          <div className="sections">
            <Typography variant="body1">Name:TS#</Typography>
            <Typography variant="body1">
              Purpose:Increases website security. It is also a session cookie.
            </Typography>
            <Typography variant="body1">
              Provider:www.catchuthere.com
            </Typography>
            <Typography variant="body1">
              Service:Typescript View Service Privacy Policy{" "}
            </Typography>
            <Typography variant="body1">Country:United States</Typography>
            <Typography variant="body1">Type:http_cookie</Typography>
            <Typography variant="body1">Expires in:session</Typography>
            <br />
          </div>
          <br />
          <br />
          <div className="sections">
            <Typography variant="body1">Name:XSRF-TOKEN</Typography>
            <Typography variant="body1">
              Purpose:This cookie is written to help with site security in
              preventing Cross-Site Request Forgery attacks.
            </Typography>
            <Typography variant="body1">
              Provider:.www.catchuthere.com
            </Typography>
            <Typography variant="body1">
              Service:Advertiser's website domain View Service Privacy Policy
            </Typography>
            <Typography variant="body1">Country:United States</Typography>
            <Typography variant="body1">Type:http_cookie</Typography>
            <Typography variant="body1">Expires in:session</Typography>
            <br />
          </div>

          <br />
          <Typography variant="h5">
            <u>Unclassified cookies:</u>
          </Typography>
          <br />

          <Typography variant="body1">
            These are cookies that have not yet been categorized. We are in the
            process of classifying these cookies.
          </Typography>
          <br />

          <div className="sections">
            <Typography variant="body1">Name:ssr-caching</Typography>
            <Typography variant="body1">Purpose:__________</Typography>
            <Typography variant="body1">
              Provider:www.catchuthere.com
            </Typography>
            <Typography variant="body1">Service:__________ </Typography>
            <Typography variant="body1">Country:United States</Typography>
            <Typography variant="body1">Type:server_cookie</Typography>
            <Typography variant="body1">
              Expires in:less than 1 minute
            </Typography>
            <br />
          </div>

          <br />
          <Typography variant="h5">
            What about other tracking technologies, like web beacons?
          </Typography>
          <br />

          <Typography variant="body1">
            Cookies are not the only way to recognize or track visitors to a
            website. We may use other, similar technologies from time to time,
            like web beacons (sometimes called "tracking pixels" or "clear
            gifs"). These are tiny graphics files that contain a unique
            identifier that enable us to recognize when someone has visited our
            Websites or opened an e-mail including them. This allows us, for
            example, to monitor the traffic patterns of users from one page
            within a website to another, to deliver or communicate with cookies,
            to understand whether you have come to the website from an online
            advertisement displayed on a third-party website, to improve site
            performance, and to measure the success of e-mail marketing
            campaigns. In many instances, these technologies are reliant on
            cookies to function properly, and so declining cookies will impair
            their functioning.
          </Typography>
          <br />

          <br />
          <Typography variant="h5">
            Do you use Flash cookies or Local Shared Objects?
          </Typography>
          <br />

          <Typography variant="body1">
            Websites may also use so-called "Flash Cookies" (also known as Local
            Shared Objects or "LSOs") to, among other things, collect and store
            information about your use of our services, fraud prevention and for
            other site operations.
            <br />
            <br />
            If you do not want Flash Cookies stored on your computer, you can
            adjust the settings of your Flash player to block Flash Cookies
            storage using the tools contained in the Website Storage Settings
            Panel. You can also control Flash Cookies by going to the Global
            Storage Settings Panel and following the instructions (which may
            include instructions that explain, for example, how to delete
            existing Flash Cookies (referred to "information" on the Macromedia
            site), how to prevent Flash LSOs from being placed on your computer
            without your being asked, and (for Flash Player 8 and later) how to
            block Flash Cookies that are not being delivered by the operator of
            the page you are on at the time).
            <br />
            <br />
            Please note that setting the Flash Player to restrict or limit
            acceptance of Flash Cookies may reduce or impede the functionality
            of some Flash applications, including, potentially, Flash
            applications used in connection with our services or online content.
          </Typography>
          <br />

          <br />
          <Typography variant="h5">
            Do you serve targeted advertising?
          </Typography>
          <br />

          <Typography variant="body1">
            Third parties may serve cookies on your computer or mobile device to
            serve advertising through our Websites. These companies may use
            information about your visits to this and other websites in order to
            provide relevant advertisements about goods and services that you
            may be interested in. They may also employ technology that is used
            to measure the effectiveness of advertisements. This can be
            accomplished by them using cookies or web beacons to collect
            information about your visits to this and other sites in order to
            provide relevant advertisements about goods and services of
            potential interest to you. The information collected through this
            process does not enable us or them to identify your name, contact
            details or other details that directly identify you unless you
            choose to provide these.
          </Typography>
          <br />

          <br />
          <Typography variant="h5">
            How often will you update this Cookie Policy?
          </Typography>
          <br />

          <Typography variant="body1">
            We may update this Cookie Policy from time to time in order to
            reflect, for example, changes to the cookies we use or for other
            operational, legal or regulatory reasons. Please therefore re-visit
            this Cookie Policy regularly to stay informed about our use of
            cookies and related technologies.
            <br />
            <br />
            The date at the top of this Cookie Policy indicates when it was last
            updated.
          </Typography>
          <br />

          <br />
          <Typography variant="h5">
            Where can I get further information?
          </Typography>
          <br />

          <Typography variant="body1">
            If you have any questions about our use of cookies or other
            technologies, please email us at hello@catchuthere.com or by post
            to:
            <br />
            <br />
            Catch U There Pty Ltd (ABN 72 638 191 885)
            <br />
            Level 8 of 121 Marcus Clarke St, Canberra ACT 2601, Australia
            <br />
            Canberra, Australian Capital Territory 2601
            <br />
            Australia
            <br />
            Phone: (+61)417420723
            <br />
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Footer currentPage="cookies" />
    </div>
  );
}
